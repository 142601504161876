import React from "../_snowpack/pkg/react.js";
import {Global} from "../_snowpack/pkg/@emotion/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import {colors, GlobalStyles} from "./theme.js";
import {GlobalStoreProvider} from "../stores/global.js";
import BackButton from "./header-back-button.js";
import Footer from "./footer-reduced.js";
export default function AppAuth(props) {
  const {children, ...rest} = props;
  return /* @__PURE__ */ React.createElement(GlobalStoreProvider, {
    initialState: {}
  }, /* @__PURE__ */ React.createElement(Global, {
    styles: GlobalStyles
  }), /* @__PURE__ */ React.createElement(AppContainer, {
    ...rest
  }, /* @__PURE__ */ React.createElement(BackButton, null), /* @__PURE__ */ React.createElement(AppInner, null, /* @__PURE__ */ React.createElement(AppInnerContainer, null, children)), /* @__PURE__ */ React.createElement(FooterR, {
    ...props
  })));
}
const AppInner = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shring: 0;
  padding-bottom: 150px;
`;
const AppInnerContainer = styled.div`
  width: 452px;
  margin: auto;
`;
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.bg};
  min-height: 100vh;
  justify-content: center;
`;
const FooterR = styled(Footer)`
  flex-shrink: 0;
`;
