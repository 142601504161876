import styled from "../_snowpack/pkg/@emotion/styled.js";
import React, {
  useEffect,
  useState
} from "../_snowpack/pkg/react.js";
import Label from "./label.js";
import InputC from "./input.js";
import TextareaC from "./textarea.js";
import SelectC from "./select.js";
import RadiosC from "./radios.js";
import CheckBoxC from "./checkbox.js";
import AddressInput from "./input-address-autocomplete.js";
import {colors, fontFamily, lineHeight} from "./theme.js";
export default function Field(props) {
  const {
    type,
    label,
    required,
    data,
    value,
    info,
    name = "",
    errors,
    readOnly,
    inline
  } = props;
  const showLabel = type !== "checkbox" && label;
  const defaultError = errors && name ? errors[name] : void 0;
  const initilaValue = sanitize(data ? data[name] : value);
  const [inputState, setInputState] = useState({
    dirty: false,
    error: defaultError,
    value: initilaValue
  });
  const {dirty, error} = inputState;
  const invalid = !!error;
  const onChange = (e) => {
    const {target} = e;
    const value2 = target.type === "checkbox" ? target.checked : target.value;
    setInputState({dirty: true, error: void 0, value: value2});
    props.onChange && props.onChange(e);
  };
  useEffect(() => {
    setInputState((s) => ({...s, error: defaultError}));
  }, [defaultError]);
  useEffect(() => {
    setInputState((s) => ({...s, value: initilaValue}));
  }, [initilaValue]);
  const id = name ? `field_${name}` : void 0;
  return /* @__PURE__ */ React.createElement(Container, {
    id,
    inline
  }, showLabel && /* @__PURE__ */ React.createElement(Label, {
    required
  }, label), /* @__PURE__ */ React.createElement(FieldInput, {
    dirty,
    invalid,
    ...props,
    value: inputState.value,
    onChange,
    readOnly: readOnly ?? false
  }), info && !error && /* @__PURE__ */ React.createElement(Info, null, info), error && /* @__PURE__ */ React.createElement(Error, null, error.message));
}
function FieldInput(props) {
  const {value} = props;
  if (props.type === "text") {
    return /* @__PURE__ */ React.createElement(InputC, {
      ...props,
      value: value ?? ""
    });
  }
  if (props.type === "email") {
    return /* @__PURE__ */ React.createElement(InputC, {
      ...props,
      value: value ?? ""
    });
  }
  if (props.type === "password") {
    return /* @__PURE__ */ React.createElement(InputC, {
      ...props,
      value: value ?? ""
    });
  }
  if (props.type === "textarea") {
    return /* @__PURE__ */ React.createElement(TextareaC, {
      ...props
    });
  }
  if (props.type === "select") {
    return /* @__PURE__ */ React.createElement(SelectC, {
      ...props
    });
  }
  if (props.type === "radio") {
    return /* @__PURE__ */ React.createElement(RadiosC, {
      ...props
    });
  }
  if (props.type === "checkbox") {
    return /* @__PURE__ */ React.createElement(CheckBoxC, {
      ...props,
      value: !!value
    });
  }
  if (props.type === "address") {
    return /* @__PURE__ */ React.createElement(AddressInput, {
      ...props
    });
  }
  return /* @__PURE__ */ React.createElement(InputC, {
    ...props
  });
}
function sanitize(v) {
  return v === null ? void 0 : v;
}
const Container = styled.div`
  width: 100%;
  ${({inline}) => inline && `width: auto;`}
`;
const Error = styled.div`
  font-family: ${fontFamily.secondary};
  font-size: 14px;
  line-height: ${lineHeight};
  color: ${colors.r6};
`;
const Info = styled.div`
  font-family: ${fontFamily.secondary};
  font-size: 12px;
  line-height: 22px;
  color: ${colors.b2};
`;
