import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {colors, fontFamily} from "./theme.js";
export default function TextArea(props) {
  const {id, placeholder, required, rows, ...rest} = props;
  const numberOfRows = rows || 4;
  return /* @__PURE__ */ React.createElement(TextAreaField, {
    id,
    placeholder,
    required,
    rows: numberOfRows,
    ...rest
  });
}
const TextAreaField = styled.textarea`
  font-family: ${fontFamily.primary};
  border: 1px solid ${colors.b5};
  color: ${colors.b2};
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  padding: 12px;
  width: 100%;
  resize: vertical;
  min-height: 48px;

  &::placeholder {
    color: ${colors.b5};
  }
  &:focus {
    outline: none;
    border-color: ${colors.c};
  }
`;
