import styled from "../_snowpack/pkg/@emotion/styled.js";
import React, {forwardRef, useState} from "../_snowpack/pkg/react.js";
import {rgba} from "../common/utils.js";
import CSRFInput from "./csrf-input.js";
import Stack from "./stack.js";
import {colors, fontFamily, lineHeight} from "./theme.js";
import InfoField from "./field-info.js";
import {t} from "../common/i18n.js";
export default forwardRef(function Form(props, ref) {
  const {
    method = "post",
    action,
    children,
    showRequiredInfo,
    onChange,
    error: initialError,
    ...rest
  } = props;
  const [error, setError] = useState(initialError);
  const onFormChange = onChange ? (e) => {
    const {target} = e;
    const {name, type, checked} = target;
    const value = type === "checkbox" ? checked : target.value;
    return onChange({[name]: value});
  } : void 0;
  const onInput = () => {
    setError(void 0);
  };
  console.log(error?.message);
  return /* @__PURE__ */ React.createElement(FormContainer, {
    method,
    action,
    ref,
    onInput,
    onChange: onFormChange,
    ...rest
  }, /* @__PURE__ */ React.createElement(CSRFInput, null), /* @__PURE__ */ React.createElement(FormInner, null, error && error.message && /* @__PURE__ */ React.createElement(FormError, {
    dangerouslySetInnerHTML: {__html: error.message}
  }), children, showRequiredInfo && /* @__PURE__ */ React.createElement(InfoField, null, t("* Dies ist ein Pflichtfeld"))));
});
const FormContainer = styled.form`
  text-align: left;
`;
const FormInner = styled(Stack)``;
const FormError = styled.div`
  font-family: ${fontFamily.primary};
  line-height: ${lineHeight};
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${colors.r6};
  background-color: ${rgba(colors.r6, 0.1)};

  a {
    color: ${colors.b};
  }
`;
