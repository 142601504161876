import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import AppAuth from "../components/app-auth.js";
import {colors, fontFamily, shadow} from "../components/theme.js";
import Stack from "../components/stack.js";
import {withAttrs} from "../common/utils.js";
import {Title} from "../components/typo.js";
import {t} from "../common/i18n.js";
import Field from "../components/field.js";
import Button from "../components/button.js";
import Logo from "../components/logo.js";
import Form from "../components/form.js";
import {useCheckValidity} from "../common/hooks.js";
import {getUrl} from "../common/universal.js";
import {Routes} from "../typings/routes.js";
import Inline from "../components/inline.js";
import BottomBanner from "../components/bottom-banner.js";
export default function Login(props) {
  const {data, error, scenarioAccount} = props;
  const {ref: formRef, validity: formValidity} = useCheckValidity();
  const errors = error ? error.errors : {};
  const resetPasswordLink = getUrl(Routes.resetPassword);
  const registerLink = getUrl(Routes.register);
  return /* @__PURE__ */ React.createElement(AppAuth, {
    ...props
  }, /* @__PURE__ */ React.createElement(LogoS, null), scenarioAccount && /* @__PURE__ */ React.createElement(BottomBanner, {
    color: "#5aff22"
  }, /* @__PURE__ */ React.createElement("p", null, `Szenario: Es wurde gerade ein neuer Account mit Email ${scenarioAccount.email} und Password ${scenarioAccount.password} erstellt.`)), /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Inner, null, /* @__PURE__ */ React.createElement(Headline, null, t("Anmelden")), /* @__PURE__ */ React.createElement(Form, {
    ref: formRef,
    error,
    showRequiredInfo: true
  }, /* @__PURE__ */ React.createElement(FormInner, null, /* @__PURE__ */ React.createElement(Field, {
    label: t("E-Mail"),
    type: "email",
    placeholder: t("Deine E-Mail eingeben"),
    required: true,
    name: "email",
    errors,
    data
  }), /* @__PURE__ */ React.createElement(Field, {
    label: t("Passwort"),
    type: "password",
    placeholder: t("Passwort eingeben"),
    required: true,
    name: "password",
    errors,
    data
  }), /* @__PURE__ */ React.createElement(Link, {
    href: resetPasswordLink
  }, t("Passwort vergessen?")), /* @__PURE__ */ React.createElement(SubmitButton, {
    type: "submit",
    disabled: !formValidity
  }, t("Einloggen")), /* @__PURE__ */ React.createElement(Text, null, t("Noch keinen Kundenkonto?"), /* @__PURE__ */ React.createElement(Link, {
    href: registerLink
  }, t("Jetzt erstellen"))))))));
}
const Container = styled.div`
  box-shadow: ${shadow.card};
  padding: 26px 31px;
`;
const Inner = styled(withAttrs(Stack, {spacing: "ml"}))``;
const Headline = styled(withAttrs(Title, {level: 1}))`
  font-size: 24px;
`;
const FormInner = styled(withAttrs(Stack, {}))``;
const LogoS = styled(Logo)`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`;
const SubmitButton = styled(withAttrs(Button, {stretch: true}))``;
const Link = styled.a`
  color: ${colors.c};
  font-family: ${fontFamily.primary};
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
`;
const Text = styled(Inline)`
  --spacing: 6px;
  display: inline-flex;
  color: ${colors.b};
  font-family: ${fontFamily.primary};
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
`;
