import styled from "../_snowpack/pkg/@emotion/styled.js";
import Radio from "./radio.js";
import React from "../_snowpack/pkg/react.js";
export default function Radios(props) {
  const {
    name,
    options,
    value,
    defaultValue,
    onChange,
    stack,
    readOnly,
    asButton,
    ...rest
  } = props;
  const controlled = value !== void 0;
  return /* @__PURE__ */ React.createElement(RadiosContainer, {
    stack: !!stack,
    ...rest
  }, options.map((o) => /* @__PURE__ */ React.createElement(Radio, {
    key: o.value,
    text: o.text,
    id: `${name}-${o.value}`,
    name,
    value: o.value,
    checked: controlled ? o.value === value : void 0,
    defaultChecked: !controlled ? o.value === defaultValue : void 0,
    onChange,
    readOnly,
    asButton
  })));
}
const RadiosContainer = styled.div`
  width: 100%;

  ${({stack}) => stack && `
    & > div {
      display: flex;
    }

    & > div + div {
      margin-top: 6px;
    }
  `}

  ${({stack}) => !stack && `
    display: flex;
    gap: 24px;
  `}
`;
