import React from "../_snowpack/pkg/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
export default function HeaderBackButton(props) {
  const onClick = () => history.back();
  return /* @__PURE__ */ React.createElement(Container, {
    onClick,
    ...props
  }, /* @__PURE__ */ React.createElement(Icon, null));
}
const Container = styled.a`
  padding: 40px;
  cursor: pointer;
`;
const Icon = () => /* @__PURE__ */ React.createElement("svg", {
  width: "18",
  height: "16",
  viewBox: "0 0 18 16",
  fill: "none"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M16.5 8.00008L1.5 8.00008",
  stroke: "#001529",
  strokeWidth: "2",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.5 1L1.5 8L8.5 15",
  stroke: "#001529",
  strokeWidth: "2",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
