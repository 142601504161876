import React from "../_snowpack/pkg/react.js";
import {getGlobalProps} from "../common/universal.js";
export default function CSRFInput() {
  let {csrfToken} = getGlobalProps();
  if (!csrfToken) {
    csrfToken = "none";
  }
  return /* @__PURE__ */ React.createElement("input", {
    type: "hidden",
    name: "_csrf",
    value: csrfToken
  });
}
