import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {colors, fontFamily, lineHeight} from "./theme.js";
export default function Radio(props) {
  const {
    text,
    name,
    value,
    id,
    checked,
    defaultChecked,
    onChange,
    readOnly,
    asButton,
    ...rest
  } = props;
  return /* @__PURE__ */ React.createElement(RadioContainer, {
    ...rest
  }, /* @__PURE__ */ React.createElement(RadioField, {
    type: "radio",
    id,
    name,
    value,
    checked,
    defaultChecked,
    onChange,
    disabled: readOnly,
    asButton
  }), asButton ? /* @__PURE__ */ React.createElement(RadioLabelButton, {
    htmlFor: id
  }, text) : /* @__PURE__ */ React.createElement(RadioLabel, {
    htmlFor: id
  }, text));
}
const RadioContainer = styled.div`
  cursor: pointer;
  width: auto;
  display: inline-flex;
  align-items: center;
`;
const RadioField = styled.input`
  //border: 1px solid ${colors.b5};
  border-radius: 2px;
  margin: 0;
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  font: inherit;
  color: ${colors.b5};
  width: 16px;
  height: 16px;
  border: 2px solid ${colors.b5};
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;

  ${({asButton}) => asButton && `display: none;`}

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.1s transform ease-out;
    box-shadow: inset 8px 8px ${colors.c};
    will-change: transform;
  }

  &:checked {
    border-color: ${colors.c};
    &::before {
      transform: scale(1);
    }
  }
`;
const RadioLabel = styled.label`
  font-family: ${fontFamily.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: ${lineHeight};
  text-align: left;
  cursor: pointer;
  padding-left: 8px;
`;
const RadioLabelButton = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  border: solid 1.5px ${colors.b5};
  color: ${colors.c};

  &:hover {
    border-color: ${colors.b4};
  }

  input:checked + & {
    border-color: ${colors.g2};
  }
`;
