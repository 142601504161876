import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {colors, fontFamily, gap} from "./theme.js";
import {nanoid} from "../_snowpack/pkg/nanoid.js";
export default function CheckBox(props) {
  const {
    label,
    name,
    value,
    defaultValue,
    dirty,
    onChange,
    required,
    invalid,
    ...rest
  } = props;
  const id = `${name}-${nanoid()}`;
  const onInputChange = (e) => {
    if (!onChange)
      return;
    onChange(e);
  };
  return /* @__PURE__ */ React.createElement(CheckBoxContainer, {
    ...rest
  }, /* @__PURE__ */ React.createElement(CheckBoxField, {
    type: "checkbox",
    name,
    id,
    checked: value,
    onChange: onInputChange,
    invalid,
    required
  }), /* @__PURE__ */ React.createElement(CheckBoxLabel, {
    htmlFor: id
  }, label));
}
const CheckBoxContainer = styled.div`
  display: flex;
  ${gap("8px")}
`;
const CheckBoxField = styled.input`
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-top: 2px;
  flex-grow: 0;
  flex-shrink: 0;
  font: inherit;
  color: ${colors.b5};
  appearance: none;
  border: 2px solid ${colors.b5};
  border-radius: 2px;
  display: grid;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 0;
    transform: scale(0);
    transition: 0.1s transform ease-out;
    box-shadow: inset 10px 10px ${colors.c};
    will-change: transform;
  }

  &:checked {
    border-color: ${colors.c};
    &::before {
      transform: scale(1);
    }
  }

  ${({invalid}) => invalid && `
      border-color: ${colors.r6};
    `}
`;
const CheckBoxLabel = styled.label`
  font-family: ${fontFamily.secondary};
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;

  a {
    color: inherit;
  }
`;
