import React, {useEffect, useState} from "../_snowpack/pkg/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import InputC from "./input.js";
import request from "../common/request.js";
import {colors, fontFamily, lineHeight, shadow} from "./theme.js";
import {debounceAsync} from "../common/utils.js";
import LoaderC from "./loader.js";
import {captureException} from "../_snowpack/pkg/@sentry/react.js";
export default function InputAddressAutocomplete(props) {
  const {onLocation, value: initialValue, className, ...rest} = props;
  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(0);
  const [cursor, setCursor] = useState(0);
  const incLoading = () => setLoading((l) => l + 1);
  const decLoading = () => setLoading((l) => l - 1);
  const wrapLoading = async (fn) => {
    incLoading();
    try {
      const resp = await fn();
      decLoading();
      return resp;
    } catch (e) {
      captureException(e);
      decLoading();
      throw e;
    }
  };
  const resetSuggestions = () => {
    setSuggestions([]);
    setCursor(0);
  };
  const onInputChange = async (e) => {
    const {value: value2} = e.target;
    setValue(value2);
    resetSuggestions();
    const suggs = await wrapLoading(() => debouncedGetSuggestion(value2));
    setSuggestions(suggs);
  };
  const selectSuggestion = async (s) => {
    resetSuggestions();
    const location = await wrapLoading(() => getLocation(s));
    onLocation && onLocation(location);
  };
  const onKeyDown = (e) => {
    if (!suggestions.length)
      return true;
    if (e.key === "ArrowDown") {
      setCursor((c) => Math.min(c + 1, suggestions.length));
      e.preventDefault();
    }
    if (e.key === "ArrowUp") {
      setCursor((c) => Math.max(c - 1, 0));
      e.preventDefault();
    }
    if (e.key === "Enter") {
      selectSuggestion(suggestions[cursor - 1]);
      e.preventDefault();
    }
  };
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return /* @__PURE__ */ React.createElement(Container, {
    className
  }, /* @__PURE__ */ React.createElement(Input, {
    ...rest,
    autoComplete: "off",
    value,
    onChange: onInputChange,
    onKeyDown
  }), loading > 0 && /* @__PURE__ */ React.createElement(Loader, null), suggestions.length > 0 && /* @__PURE__ */ React.createElement(Suggestions, null, suggestions.map((s, i) => /* @__PURE__ */ React.createElement(SuggestionEl, {
    key: s.magicKey,
    onClick: () => selectSuggestion(s),
    onMouseEnter: () => setCursor(i + 1),
    onMouseLeave: () => setCursor(0),
    active: cursor === i + 1
  }, s.text))));
}
const debouncedGetSuggestion = debounceAsync(getSuggestions, 200);
function getSuggestions(text) {
  return requestAbortable("/address/suggestions", {text});
}
async function getLocation(s) {
  const locations = await requestAbortable("/address/locations", s);
  return locations[0];
}
let controller = new AbortController();
function requestAbortable(url, body) {
  controller.abort();
  controller = new AbortController();
  return request(url, {
    method: "POST",
    signal: controller.signal,
    body
  }).catch((e) => {
    if (e.name === "AbortError") {
      return [];
    }
    throw e;
  });
}
const Container = styled.div`
  position: relative;
`;
const Input = styled(InputC)``;
const Suggestions = styled.ul`
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  right: 0;
  background: white;
  box-shadow: ${shadow.dropdown};
  font-family: ${fontFamily.secondary};
  font-size: 14px;
  line-height: ${lineHeight};
  border: 1px solid ${colors.b5};
  border-top: 0;
  color: ${colors.b2};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 100;
  visibility: hidden;

  input:focus + & {
    border-color: ${colors.c};
    visibility: visible;
  }

  &:hover {
    visibility: visible;
  }
`;
const SuggestionEl = styled.li`
  cursor: pointer;
  padding: 10px;

  ${({active}) => active && `
      background: ${colors.c};
      color: white;
  `}
`;
const Loader = styled(LoaderC)`
  position: absolute;
  top: 15px;
  right: 10px;
  width: 20px;
  height: 20px;
`;
