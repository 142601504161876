import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {fontFamily, lineHeight} from "./theme.js";
export default function FieldInfo(props) {
  const {children} = props;
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(InfoField, null, children));
}
const Container = styled.div`
  width: 100%;
`;
const InfoField = styled.p`
  font-family: ${fontFamily.primary};
  background-color: transparent;
  font-size: 12px;
  line-height: ${lineHeight};
`;
