import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "../_snowpack/pkg/react.js";
import {onResize, parseQuery, replaceSearchQuery} from "./utils.js";
import request from "./request.js";
import {captureException} from "../_snowpack/pkg/@sentry/react.js";
export function useAsync(fn, deps) {
  const [resp, setResp] = useState({});
  const requestId = useRef(0);
  function done(id, resp2) {
    if (requestId.current === 0) {
      return;
    }
    if (id < requestId.current) {
      return;
    }
    requestId.current = 0;
    setResp(resp2);
  }
  async function run(curr) {
    const id = curr + 1;
    setResp({});
    try {
      requestId.current = id;
      const value = await fn();
      done(id, {value});
    } catch (error) {
      captureException(error);
      done(id, {error});
    }
  }
  useEffect(() => {
    run(requestId.current);
  }, deps);
  return resp;
}
export function useFetch(initialUrl, initParams) {
  const [url, setUrl] = useState(initialUrl);
  const [params] = useState(initParams);
  const [resp, setResp] = useState({type: "pending"});
  async function doFetch() {
    setResp({type: "pending"});
    try {
      const value = await request(url);
      setResp({type: "success", value});
    } catch (error) {
      captureException(error);
      setResp({type: "error", error});
    }
  }
  const paramsDeps = params ? Object.values(params) : [];
  useEffect(() => {
    doFetch();
  }, [url, ...paramsDeps]);
  return [resp, setUrl, doFetch];
}
export function useMap(initialState) {
  const map = new Map(Object.entries(initialState));
  const [state, setState] = useState(map);
  const setMap = (key, value) => setState(map.set(key, value));
  return [state, setMap];
}
export function useRect(beforeRect) {
  const ref = useRef(null);
  const [rect, setRect] = useState();
  const res = {ref, rect};
  const handleResize = useCallback(() => {
    if (!ref.current)
      return;
    const afterRect = beforeRect && beforeRect(ref.current);
    const rect2 = ref.current.getBoundingClientRect();
    afterRect && afterRect(ref.current);
    setRect(rect2);
  }, [ref.current]);
  useEffect(() => {
    if (!ref.current)
      return;
    handleResize();
    onResize(handleResize);
  }, [ref.current]);
  return res;
}
export function useCheckValidity(deps = []) {
  const ref = useRef(null);
  const [validity, setValidity] = useState();
  function check() {
    if (!ref.current)
      return;
    const validity2 = ref.current.checkValidity();
    setValidity(validity2);
  }
  const postponedCheck = useCallback(() => setTimeout(check, 0), [ref.current]);
  useEffect(() => {
    postponedCheck();
    ref.current?.addEventListener("change", postponedCheck);
    ref.current?.addEventListener("input", postponedCheck);
    return () => {
      ref.current?.removeEventListener("change", postponedCheck);
      ref.current?.removeEventListener("input", postponedCheck);
    };
  }, [ref.current, ...deps]);
  return {ref, validity};
}
export function useOnClickOutside(handler) {
  const ref = useRef(null);
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler();
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchend", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchend", listener);
    };
  }, [ref, handler]);
  return ref;
}
const canUseDOM = typeof window !== "undefined";
export const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;
export function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    if (hasWindow) {
      let handleResize = function() {
        setWindowDimensions(getWindowDimensions());
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);
  return windowDimensions;
}
export const useIframeListener = (fn, iframeSrc) => {
  useIsomorphicLayoutEffect(() => {
    async function ready(e) {
      if (e.data.event !== "ready")
        return;
      fn();
    }
    window.addEventListener("message", ready, false);
    return () => {
      window.removeEventListener("message", ready);
    };
  }, [iframeSrc]);
};
export function useSearchParams() {
  const [searchParams, setSearchParams] = useState(parseQuery());
  function set(qs) {
    const newParams = {...parseQuery(), ...qs};
    setSearchParams(newParams);
    replaceSearchQuery(newParams);
  }
  function reset(qs) {
    setSearchParams(qs);
    replaceSearchQuery(qs);
  }
  return [searchParams, set, reset];
}
