import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {colors, fontFamily, lineHeight} from "./theme.js";
export default function Input(props) {
  const {
    name,
    type = "text",
    placeholder = " ",
    required,
    defaultValue,
    invalid = false,
    dirty = false,
    value,
    ...rest
  } = props;
  const id = `${name}-id`;
  return /* @__PURE__ */ React.createElement(InputField, {
    type,
    name,
    defaultValue,
    value,
    id,
    placeholder,
    required,
    invalid,
    dirty,
    ...rest
  });
}
const invalidStyles = `
  border-color: ${colors.r6};
`;
const readonlydStyles = `
  background-color: ${colors.b6};
  pointer-events: none;
`;
const InputField = styled.input`
  font-family: ${fontFamily.secondary};
  border: 1px solid ${colors.b5};
  color: ${colors.b2};
  border-radius: 4px;
  font-size: 16px;
  line-height: ${lineHeight};
  padding: 11px 12px;
  width: 100%;

  ${({small}) => small && `
    font-size: 14px;
    padding: 5px 8px;
  `}

  ${({inline}) => inline && `
    display: inline-block;
    width: auto;
  `}

  &::placeholder {
    color: ${colors.b4};
  }

  &:focus {
    outline: none;
    border-color: ${colors.c};
  }

  ${({type}) => type === "number" && `
    padding-right: 0;
  `}

  ${({invalid}) => invalid && `
      &,
      &:focus {
        ${invalidStyles}
      }
    `}

  ${({dirty}) => dirty && `
      &:invalid {
        ${invalidStyles}
      }
    `}

    ${({readOnly}) => readOnly && `
      & {
        ${readonlydStyles}
      }
    `}
`;
