import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {fontFamily, lineHeight} from "./theme.js";
export default function Label(props) {
  const {children, required, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Container, {
    ...rest
  }, children, required && " *");
}
const Container = styled.label`
  display: block;
  font-family: ${fontFamily.secondary};
  font-size: 14px;
  line-height: ${lineHeight};
  margin-bottom: 2px;
  width: 100%;
  text-align: left;

  a {
    color: inherit;
  }
`;
