import styled from "../_snowpack/pkg/@emotion/styled.js";
import {ChevronDown} from "./icons.js";
import React, {
  useEffect,
  useState
} from "../_snowpack/pkg/react.js";
import {colors, fontFamily} from "./theme.js";
import {t} from "../common/i18n.js";
export default function Select(props) {
  const {
    id,
    name,
    options,
    required,
    placeholder,
    onChange,
    value,
    defaultValue,
    invalid,
    readOnly,
    ...rest
  } = props;
  const controlled = value !== void 0;
  const initialValue = controlled ? value : defaultValue;
  const [selected, setSelected] = useState(controlled ? value : defaultValue);
  const onSelect = (e) => {
    setSelected(e.target.value);
    onChange && onChange(e);
  };
  const selectedText = options.find((o) => o.value.toString() === selected)?.text;
  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);
  return /* @__PURE__ */ React.createElement(Container, {
    ...rest,
    readOnly: readOnly ?? false
  }, /* @__PURE__ */ React.createElement(SelectField, {
    id,
    name,
    value: selected,
    onChange: onSelect,
    required,
    readOnly: readOnly ?? false
  }, /* @__PURE__ */ React.createElement(SelectOption, {
    disabled: true,
    selected: true
  }, t("Bitte auswählen")), options.map((o) => /* @__PURE__ */ React.createElement(SelectOption, {
    key: o.value.toString(),
    value: o.value.toString()
  }, o.text))), /* @__PURE__ */ React.createElement(SelectPlaceholder, {
    hasValue: !!selected,
    invalid
  }, selectedText || placeholder), /* @__PURE__ */ React.createElement(IconChevron, null));
}
const Container = styled.div`
  position: relative;

  ${({readOnly}) => readOnly && `
      background-color: ${colors.b6};
      pointer-events: none;
      cursor: default;
    `}
`;
const SelectField = styled.select`
  cursor: pointer;
  border: 1px solid ${colors.b5};
  border-radius: 4px;
  margin: 0 8px 0 0;
  width: 100%;
  height: 48px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
`;
const SelectOption = styled.option``;
const SelectPlaceholder = styled.span`
  font-family: ${fontFamily.secondary};
  font-size: 16px;
  line-height: 32px;
  height: 48px;
  border: 1px solid ${colors.b5};
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 32px 8px 12px;
  display: block;
  color: ${colors.b5};

  ${({hasValue}) => hasValue && `
      color: ${colors.b2};
    `}

  select:focus + & {
    border-color: ${colors.c};
  }

  ${({invalid}) => invalid && `
      &,
      &:focus {
        border-color: ${colors.r6};
      }
    `}
`;
const IconChevron = styled(ChevronDown)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;
